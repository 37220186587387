import type { RouteRecordRaw } from 'vue-router'

const Layout = () => import('@/layouts/index.vue')

const routes: RouteRecordRaw = {
  path: '/policyIndustry',
  component: Layout,
  redirect: '/policyIndustry/index',
  name: 'PolicyIndustry',
  meta: {
    title: '政策行业管理',
    i18n: 'route.breadcrumb.root',
    icon: 'ic:twotone-double-arrow',
  },
  children: [
    {
      path: 'index',
      name: 'PolicyIndustryIndex',
      component: () => import('@/views/policyIndustry/index.vue'),
      meta: {
        title: '政策行业管理',
        sidebar: false,
        breadcrumb: false,
        activeMenu: "/policyIndustry"
      },
    }
  ],
}

export default routes
