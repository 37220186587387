import useTabbarStore from './tabbar'
import useRouteStore from './route'
import useMenuStore from './menu'
import api from '@/api'
import storage from '@/utils/storage'

const useUserStore = defineStore(
  // 唯一ID
  'user',
  () => {
    const tabbarStore = useTabbarStore()
    const routeStore = useRouteStore()
    const menuStore = useMenuStore()

    const account = ref(storage.local.get('account') ?? '')
    const token = ref(storage.local.get('token') ?? '')
    const failure_time = ref(storage.local.get('failure_time') ?? '')
    const permissions = ref<string[]>([])
    const isLogin = computed(() => {
      let retn = false
      if (token.value) {
        retn = true;
      }
      return retn;
    })

    // 登录
    async function login(data: {
      username: string
      password: string
    }) {
      // 通过 mock 进行登录
      // const res = await api.post('member/login', data, {
      //   baseURL: '/mock/',
      // })
      const res = await api.post('user/login', data)
      // storage.local.set('account', res.data.account)
      storage.local.set('token', res.token)
      // storage.local.set('failure_time', res.data.failure_time)
      // account.value = res.data.account
      token.value = res.token
      // failure_time.value = res.data.failure_time
    }
    // 登出
    async function logout() {
      // storage.local.remove('account')
      storage.local.remove('token')
      // storage.local.remove('failure_time')
      // account.value = ''
      token.value = ''
      // failure_time.value = ''
      permissions.value = []
      tabbarStore.clean()
      routeStore.removeRoutes()
      menuStore.setActived(0)
    }
    // 获取我的权限
    async function getPermissions() {
      // 通过 mock 获取权限
      const res = await api.get('member/permission', {
        baseURL: '/mock/',
        params: {
          account: account.value,
        },
      })
      permissions.value = res.data.permissions
      return permissions.value
    }
    // 修改密码
    async function editPassword(data: {
      password: string
      newpassword: string
    }) {
      await api.post('member/edit/password', {
        account: account.value,
        password: data.password,
        newpassword: data.newpassword,
      }, {
        baseURL: '/mock/',
      })
    }

    return {
      account,
      token,
      permissions,
      isLogin,
      login,
      logout,
      getPermissions,
      editPassword,
    }
  },
)

export default useUserStore
