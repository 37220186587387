import type { RouteRecordRaw } from 'vue-router'

const Layout = () => import('@/layouts/index.vue')

const routes: RouteRecordRaw = {
  path: '/order',
  component: Layout,
  redirect: '/order/index',
  name: 'Order',
  meta: {
    title: '订单管理',
    i18n: 'route.breadcrumb.root',
    icon: 'ic:twotone-double-arrow',
  },
  children: [
    {
      path: 'index',
      name: 'OrderIndex',
      component: () => import('@/views/order/index.vue'),
      meta: {
        title: '订单管理',
        sidebar: false,
        breadcrumb: false,
        activeMenu: "/order"
      },
    }
  ],
}

export default routes
