import type { RouteRecordRaw } from 'vue-router'

const Layout = () => import('@/layouts/index.vue')

const routes: RouteRecordRaw = {
  path: '/banner',
  component: Layout,
  redirect: '/banner/index',
  name: 'Banner',
  meta: {
    title: '轮播图管理',
    i18n: 'route.breadcrumb.root',
    icon: 'ic:twotone-double-arrow',
  },
  children: [
    {
      path: 'index',
      name: 'BannerIndex',
      component: () => import('@/views/banner/index.vue'),
      meta: {
        title: '轮播图管理',
        sidebar: false,
        breadcrumb: false,
        activeMenu: "/banner"
      },
    }
  ],
}

export default routes
