import type { RouteRecordRaw } from 'vue-router'

const Layout = () => import('@/layouts/index.vue')

const routes: RouteRecordRaw = {
  path: '/convert',
  component: Layout,
  redirect: '/convert/index',
  name: 'Convert',
  meta: {
    title: '兑换码管理',
    i18n: 'route.breadcrumb.root',
    icon: 'ic:twotone-double-arrow',
  },
  children: [
    {
      path: 'index',
      name: 'ConvertIndex',
      component: () => import('@/views/convert/index.vue'),
      meta: {
        title: '兑换码管理',
        sidebar: false,
        breadcrumb: false,
        activeMenu: '/convert',
      },
    },
  ],
}

export default routes
