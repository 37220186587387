import type { RouteRecordRaw } from 'vue-router'

const Layout = () => import('@/layouts/index.vue')

const routes: RouteRecordRaw = {
  path: '/sale',
  component: Layout,
  redirect: '/sale/index',
  name: 'Sale',
  meta: {
    title: '分销管理',
    i18n: 'route.breadcrumb.root',
    icon: 'ic:twotone-double-arrow',
  },
  children: [
    {
      path: 'index',
      name: 'SaleIndex',
      component: () => import('@/views/sale/index.vue'),
      meta: {
        title: '分销管理',
        sidebar: false,
        breadcrumb: false,
        activeMenu: "/sale"
      },
    }
  ],
}

export default routes
